import React from "react"
import PropTypes from "prop-types"
import GraphImg from "graphcms-image"
import HtmlRenderer from "./htmlRenderer"

const TechStackHeader = ({sectionData}) => (
	<div className="full-width-image-section">
		<GraphImg
			image={sectionData[0].imageLeft}
			fit="scale"
			backgroundColor={true}
			withWebp={true}
			alt={sectionData[0].imageLeft.fileName} />
		<HtmlRenderer
			htmlData={sectionData[0].contentLeft.html}
			rendererID="js-tech-stack-renderer-1"
			rendererClass="css-renderer orangeBg bold"/>
		<HtmlRenderer
			htmlData={sectionData[0].contentRight.html}
			rendererID="js-tech-stack-renderer-2"
			rendererClass="css-renderer blueBg bold"/>
		<GraphImg
			image={sectionData[0].imageRight}
			fit="scale"
			backgroundColor={true}
			withWebp={true}
			alt={sectionData[0].imageRight.fileName} />
	</div>
)

TechStackHeader.propTypes = {
	sectionData: PropTypes.array.isRequired,
}

export default TechStackHeader
